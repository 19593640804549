<template>
  <v-container>
    <v-row>
      <v-col cols='12'
             class='text-h6'>
        {{ product.title }}
      </v-col>
    </v-row>

    <!-- Skeleton loader -->
    <v-row v-if='loading'>
      <v-col cols='8'>
        <v-skeleton-loader type='card' />
      </v-col>
      <v-col cols='4'>
        <v-skeleton-loader type='article, actions' />
      </v-col>
    </v-row>
    <v-row v-if='loading'>
      <v-col v-for='n in 3'
             :key='`skeleton-bottom-${n}`'
             class='pa-5'
             cols='4'>
        <v-skeleton-loader type='card' />
      </v-col>
    </v-row>

    <!-- Main infos -->
    <v-row class='mb-5'>
      <v-spacer />
      <!-- Images -->
      <v-col cols='12'
             md='5'>
        <v-row>
          <v-col cols='12'>
            <v-img :lazy-src='selectedVariant.lazy_images_urls[selectedImage]'
                   :src='selectedVariant.images_urls[selectedImage]'
                   aspect-ratio='0.8'
                   contain
                   class='display-picture'
                   @click='dialog = !dialog' />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'
                 class='d-none d-md-flex justify-center'>
            <v-card elevation='1'>
              <v-slide-group v-model='selectedImage'
                             mandatory
                             center-active
                             show-arrows>
                <v-slide-item v-for='(url, index) in selectedVariant.lazy_images_urls'
                              :key='index'
                              v-slot='{ active, toggle }'>
                  <v-card class='ma-5'
                          height='100'
                          width='100'
                          elevation='0'
                          @click='toggle'>
                    <v-row align='center'
                           justify='center'>
                      <v-img :src='url'
                             contain
                             aspect-ratio='1' />
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols='12'
                 class='d-md-none'>
            <v-card class='mt-5'
                    elevation='0'>
              <v-slide-group v-model='selectedImage'
                             center-active
                             mandatory
                             show-arrows>
                <v-slide-item v-for='(url, index) in selectedVariant.lazy_images_urls'
                              :key='index'
                              v-slot='{ active, toggle }'>
                  <v-card height='50'
                          width='50'
                          class='ma-4'
                          :elevation='active ? 10 : 0'
                          @click='toggle'>
                    <v-row align='center'
                           justify='center'>
                      <v-img :src='url' />
                    </v-row>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
      <!-- Description / options / price / qty -->
      <v-col cols='12'
             md='4'
             class='pt-10'>
        <!-- Description -->
        <v-row class='mb-10'>
          <v-col class='simple-format'>
            {{ selectedVariant.description }}
          </v-col>
        </v-row>

        <v-row class='mb-5'>
          <v-col>
            <p class='font-weight-thin black--text text-body-2'>
              Prix unitaire {{ unitPrice(qtySelected) | currencyDefault }}
            </p>
            <p class='font-weight-bold price-color text-body-1'>
              Prix {{ price(qtySelected) | currencyDefault }}
            </p>
          </v-col>
        </v-row>

        <!--Qty add to cart-->
        <v-row v-if='availableQuantities.length'>
          <!-- qty -->
          <v-col cols='12'>
            <v-select :items='availableQuantities'
                      v-model='qtySelected'
                      hide-details='auto'>
              <template v-slot:item='{ item }'>
                <v-row class='d-flex justify-center align-center show-labels'>
                  <v-col>
                    {{ item }}
                  </v-col>
                  <v-col>
                    <span class='text-caption font-weight-thin blue--text'>prix unitaire</span>
                    {{ unitPrice(item) | currencyDefault }}
                  </v-col>
                  <v-col>
                    <span class='text-caption font-weight-thin blue--text'>prix</span>
                    {{ price(item) | currencyDefault }}
                  </v-col>
                </v-row>
              </template>
            </v-select>
          </v-col>
          <!-- Add product to cart -->
          <v-col cols='12'
                 v-if='isInCart'>
            <router-link to='/carts'
                         class='text-decoration-none'>
              <v-btn text
                     class='custom-btn amber accent-1'>
                Voir le panier
              </v-btn>
            </router-link>
          </v-col>
          <v-col cols='12'
                 v-else>
            <v-btn text
                   class='custom-btn ubn-bg-color white--text'
                   @click='addToCart'>
              Ajouter au panier
            </v-btn>
          </v-col>
          <v-col cols='12'
                 class='text-body-1 font-weight-bold'>
            {{ errorMessage }}
          </v-col>
        </v-row>

        <!-- Unavailable product -->
        <v-row v-else>
          <v-col cols='1'>
            <font-awesome-icon icon='info-circle' />
          </v-col>
          <v-col class='text-caption'>
            <p>
              Ce produit n'est pas disponible pour l'instant
              <br>
              contacter nous par mail pour plus d'informations
              <br>
              <a href='mailto:contact@une-bonne-nouvelle.fr'>contact@une-bonne-nouvelle.fr</a>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-spacer />
    </v-row>

    <v-divider />

    <!-- Related products -->
    <v-row class='mt-10'>
      <span class='text-h5 ml-10'>Dans la même gamme :</span>
    </v-row>
    <v-row class='mt-10'>
      <v-col cols='4'
             class='mb-5'
             v-for='relatedProduct in product.related_products'
             :key='`related-product-${relatedProduct.data.id}`'>
        <router-link :to='`/products/${relatedProduct.data.id}`'
                     class='text-decoration-none'>
          <v-row>
            <v-spacer />
            <v-col cols='8'
                   md='6'>
              <v-img :lazy-src='relatedProduct.data.attributes.lazy_image_url'
                     :src='relatedProduct.data.attributes.image_url'
                     aspect-ratio='1'
                     contain
                     class='img-zoom-in' />
            </v-col>
            <v-spacer />
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols='12'
                   class='text-center'>
              {{ relatedProduct.data.attributes.title }}
            </v-col>
            <v-spacer />
          </v-row>
        </router-link>
      </v-col>
    </v-row>

    <!-- Display picture -->
    <v-dialog v-model='dialog'
              hide-overlay
              max-width='60%'>
      <v-card>
        <v-img :lazy-src='selectedVariant.lazy_images_urls[selectedImage]'
               :src='selectedVariant.images_urls[selectedImage]'
               aspect-ratio='0.8'
               contain />
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  export default {
    name: 'Product',
    data: () => ({
      product: {},
      qtySelected: null,
      selectedVariant: { lazy_images_urls: [], images_urls: [], price: {} },
      loading: true,
      selectedImage: null,
      isInCart: false,
      errorMessage: '',
      dialog: false
    }),
    computed: {
      availableQuantities() {
        if(!this.selectedVariant.quantity || this.selectedVariant.quantity.disabled) { return []; }

        const array = [];
        const qty = this.selectedVariant.quantity;

        for (let x = qty.from; x <= qty.to; x = (x + qty.incr)) {
          array.push(x)
        }

        return array;
      },
    },
    watch: {
      $route() { this.resetData(); }
    },
    async mounted() {
      await this.$vuetify.goTo(0, { duration: 300, offset: 0, easing: 'linear' });

      await this.fetchProduct();
      this.setBreadcrumbs();

      const items = (localStorage.getItem('cart_items') || '').split('/');

      this.isInCart = items.filter(item => Number(item) === this.selectedVariant.id).length > 0;
    },
    methods: {
      fetchProduct() {
        return new Promise(resolve => {
          this.axios.get(`/v1/products/${this.$route.params.id}`)
                    .then(response => {
                      this.product = response.data.data.attributes;
                      this.selectedVariant = this.product.variants[0].data.attributes;
                      this.selectedImage = 0;
                      this.qtySelected = this.availableQuantities[0];

                      this.loading = false;
                      resolve();
                    });
        })
      },
      setBreadcrumbs() {
        const items = [{ text: this.product.title, to: `/products/${this.$route.params.id}` }];
        items.push({ text: this.product.category.title, to: `/categories/${this.product.category.id}` });

        if(this.product.category.parents) {
          this.product.category.parents.map(parent => {
            items.push({ text: parent.title, to: '' })
          });
        }

        this.$root.$emit('updateBreadcrumbs', items.reverse());
      },
      addToCart() {
        const cart = localStorage.getItem('cart_secure_session');

        if(cart) {
          this.addVariantToCart(cart);
        } else {
          this.axios.post('/v1/carts')
                    .then(response => {
                      localStorage.setItem('cart_secure_session', response.data);
                      this.$root.$emit('openCart');
                      this.addVariantToCart(response.data);
                    });
        }
      },
      addVariantToCart(cart) {
        const params = {
          secure_session: cart,
          cart_item: {
            quantity: this.qtySelected,
            variant_id: this.product.variants[0].data.id
          }
        };

        this.axios.post('/v1/cart_items', params)
                  .then(() => {
                    const items = (localStorage.getItem('cart_items') || '').split('/');

                    items.push(this.selectedVariant.id)

                    localStorage.setItem('cart_items', items.join('/'));

                    this.isInCart = true;
                  })
                  .catch(error => {
                    if(error.response.data.errors !== 'incompatible payment_type') return;

                    this.errorMessage =
                        'Les produits ajoutés au panier ne sont pas compatible avec le produit que vous souhaiter' +
                        ' y ajouter, si vous souhaitez passer ce produit en commande il vous faudra valider ou effacer votre panier'
                  });
      },
      unitPrice(qty) {
        const price = this.selectedVariant.price;
        let value = price.value;

        if(price.price_limit && price.price_limit < qty) qty = price.price_limit;

        if(price.price_type === 'formula') value = value.replaceAll('{qty}', qty);

        return Math.round(eval(value) * 100) / 100;
      },
      price(qty) {
        return this.unitPrice(qty) * qty;
      },
      async resetData() {
        if (this.$route.name !== 'product') return;

        this.loading = true;
        await this.$vuetify.goTo(0, { duration: 300, offset: 0, easing: 'linear' })

        await this.fetchProduct();
        this.setBreadcrumbs();

        const items = (localStorage.getItem('cart_items') || '').split('/');

        this.isInCart = items.filter(item => Number(item) === this.selectedVariant.id).length > 0;
      }
    },
  }
</script>

<style lang='scss' scoped>
  .show-labels {
    span {
      display: none;
    }
  }

  .show-labels:hover {
    span {
      display: inline-block;
    }
  }

  .img-zoom-in {
    transition: 200ms transform ease-in-out;
  }

  .img-zoom-in:hover {
    transform: scale(1.04);
  }

  .display-picture:hover {
    cursor: zoom-in;
  }

  .price-color {
    color: #ac7b42;
  }
</style>
